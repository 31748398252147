import React, { useState } from "react";
import "./App.css";
import Scope from "./Scope";
import ImageUploader from './ImageUploader';

function App() {
  const [overlayVisible, setOverlayVisible] = useState(true);
  const [copyMessageVisible, setCopyMessageVisible] = useState(false);


  const handleButtonClick = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleScopeClick = () => {
    setOverlayVisible(false);
  };

  const handleCopyClick = () => {
    const contractAddress = 'HnQqddF6bcEZ6mim6wwrcPSUB8NFineRAos9VH9FfZ1h';
    navigator.clipboard.writeText(contractAddress).then(() => {
      setCopyMessageVisible(true);
      setTimeout(() => {
        setCopyMessageVisible(false);
      }, 2000); // Hide the message after 2 seconds
    }).catch((err) => {
      console.error('Failed to copy: ', err);
    });
  };

  return (
    <div className="App" style={{overflowY:'auto', maxHeight:'100vh'
    }}>
      <div
        className="background"
        style={{
          backgroundImage: "url(/background.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "100%",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      ></div>
      <div className="content">
      <img src="/logo360ns.png" alt="Logo" style={{ width: '200px', height: 'auto' }} />
        <h1
          style={{
            color: "#FF44CC",
            background: "black",
            borderRadius: "10px",
          }}
        >
          360 NOSCOPE INSTASWAP WALLBANG
        </h1>
        <h2
        onClick={handleCopyClick}
          style={{
            color: "#FF44CC",
            background: "black",
            borderRadius: "8px",
            padding: "8px",
          }}
        >
          HnQqddF6bcEZ6mim6wwrcPSUB8NFineRAos9VH9FfZ1h
        </h2>
         {copyMessageVisible && <div className="copy-message">Copied!</div>}
        <ul>
          <li>
            <button
              onClick={() =>
                handleButtonClick(
                  "https://www.pump.fun/HnQqddF6bcEZ6mim6wwrcPSUB8NFineRAos9VH9FfZ1h"
                )
              }
            >
              Pump Fun Link
            </button>
          </li>
          <li>
            <button
              onClick={() =>
                handleButtonClick("https://t.me/SOL360NOSCOPEINSTASWAPWALLBANG")
              }
            >
              Telegram Chat
            </button>
          </li>
          <li>
            <button onClick={() => handleButtonClick('https://www.dextools.io/app/en/solana/pair-explorer/DFjwCoJq8MGGwat288H78DfSAdgbXzKDww7jYDGutvhv?t=1718915445850')}>
              Dextools
            </button>
          </li>
          <li>
            <button onClick={() => handleButtonClick('https://dexscreener.com/solana/dfjwcojq8mggwat288h78dfsadgbxzkdww7jydgutvhv')}>
              DexScreener
            </button>
          </li>
          <li>
            <button onClick={() => handleButtonClick('https://raydium.io/swap/?inputMint=sol&outputMint=4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R')}>
              Buy $360NS
            </button>
          </li>
         
        </ul>
      </div>
      {overlayVisible && <Scope onScopeClick={handleScopeClick} />}
      <ImageUploader />
    </div>
  );
}

export default App;