import React, { useState } from 'react';

const ImageUploader = () => {
  const [processedImage, setProcessedImage] = useState(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        addSniperScope(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const addSniperScope = (imageSrc) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.src = imageSrc;
    img.onload = () => {
      const scope = new Image();
      scope.src = '/sniper-scope.png'; // Ensure this image is in the public folder
      scope.onload = () => {
        canvas.width = scope.width;
        canvas.height = scope.height;
        ctx.drawImage(img, 0, 0, scope.width, scope.height);
        ctx.drawImage(scope, 0, 0);
        // Add text
        ctx.font = '30px Arial';
        ctx.fillStyle = 'white';
        ctx.textAlign = 'center';
        ctx.fillText('$360NS', scope.width / 2, scope.height - 30);
        setProcessedImage(canvas.toDataURL());
      };
    };
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '20px', overflowY: 'visible', maxHeight: '100vh' }}>
      <input type="file" accept="image/*" onChange={handleImageUpload} style={{ marginBottom: '20px' }} />
      {processedImage && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <div style={{ width: 'fit-content', overflow: 'hidden' }}>
            <img src={processedImage} alt="Processed" style={{ display: 'block', maxWidth: '100%', height: 'auto' }} />
          </div>
        </div>
      )}
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px', gap: '20px', flexWrap: 'wrap' }}>
        <img src="/image1.png" alt="Example 1" style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
        <img src="/image2.png" alt="Example 2" style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
        <img src="/image3.png" alt="Example 3" style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
      </div>
      <style>
        {`
          @media (max-width: 600px) {
            .example-images img {
              width: 100px;
              height: 100px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default ImageUploader;