import React, { useState, useEffect } from 'react';
import './Scope.css';

const Scope = ({ onScopeClick }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [overlayVisible, setOverlayVisible] = useState(true);


  const handleMouseMove = (event) => {
    setPosition({
      x: event.clientX,
      y: event.clientY,
    });
  };

  const handleClick = () => {
    const audio = new Audio('/audio.mp3');
    audio.play();
    if (overlayVisible) {
      setOverlayVisible(false);
      if (onScopeClick) {
        onScopeClick();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div onClick={handleClick}>
          {overlayVisible && (
        <div
          id="overlay"
          style={{
            maskImage: `radial-gradient(circle 75px at ${position.x}px ${position.y}px, transparent 0%, transparent 75px, black 75px)`,
            WebkitMaskImage: `radial-gradient(circle 75px at ${position.x}px ${position.y}px, transparent 0%, transparent 75px, black 75px)`, // For Safari support
          }}
        ></div>
      )}
      <div
        id="scope"
        style={{
          left: `${position.x - 75}px`,
          top: `${position.y - 75}px`,
        }}
      >
        <div id="clickable-layer"></div>
        <div className="crosshair horizontal"></div>
        <div className="crosshair vertical"></div>
      </div>
    </div>
  );
};

export default Scope;